import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '..\\layouts\\error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_plugin_2381e48f from 'nuxt_plugin_plugin_2381e48f' // Source: .\\components\\plugin.js (mode: 'all')
import nuxt_plugin_pluginsdynamic05485147_0a0e0081 from 'nuxt_plugin_pluginsdynamic05485147_0a0e0081' // Source: .\\plugins.dynamic.05485147.js (mode: 'all')
import nuxt_plugin_plugin_4e07b9c5 from 'nuxt_plugin_plugin_4e07b9c5' // Source: .\\vuetify\\plugin.js (mode: 'all')
import nuxt_plugin_recaptcha_6d721ca1 from 'nuxt_plugin_recaptcha_6d721ca1' // Source: .\\recaptcha.js (mode: 'all')
import nuxt_plugin_vuewebcam_f588e88e from 'nuxt_plugin_vuewebcam_f588e88e' // Source: .\\vue-web-cam.js (mode: 'client')
import nuxt_plugin_nuxtvuexlocalstorage_2dc31336 from 'nuxt_plugin_nuxtvuexlocalstorage_2dc31336' // Source: .\\nuxt-vuex-localstorage.js (mode: 'client')
import nuxt_plugin_toast_5aae783e from 'nuxt_plugin_toast_5aae783e' // Source: .\\toast.js (mode: 'client')
import nuxt_plugin_nuxtvuexroutersync_2c511c1a from 'nuxt_plugin_nuxtvuexroutersync_2c511c1a' // Source: .\\nuxt-vuex-router-sync.js (mode: 'all')
import nuxt_plugin_pluginclient_d19f3476 from 'nuxt_plugin_pluginclient_d19f3476' // Source: .\\content\\plugin.client.js (mode: 'client')
import nuxt_plugin_pluginserver_062c5166 from 'nuxt_plugin_pluginserver_062c5166' // Source: .\\content\\plugin.server.js (mode: 'server')
import nuxt_plugin_axios_06787732 from 'nuxt_plugin_axios_06787732' // Source: .\\axios.js (mode: 'all')
import nuxt_plugin_pluginrouting_6359c072 from 'nuxt_plugin_pluginrouting_6359c072' // Source: .\\nuxt-i18n\\plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_681c3908 from 'nuxt_plugin_pluginmain_681c3908' // Source: .\\nuxt-i18n\\plugin.main.js (mode: 'all')
import nuxt_plugin_axios_3566aa80 from 'nuxt_plugin_axios_3566aa80' // Source: ..\\plugins\\axios (mode: 'all')
import nuxt_plugin_observevisibility_cb10434e from 'nuxt_plugin_observevisibility_cb10434e' // Source: ..\\plugins\\observe-visibility (mode: 'client')
import nuxt_plugin_vueuuid_9db4d5d0 from 'nuxt_plugin_vueuuid_9db4d5d0' // Source: ..\\plugins\\vue-uuid (mode: 'client')
import nuxt_plugin_vuedndzone_680f8b5c from 'nuxt_plugin_vuedndzone_680f8b5c' // Source: ..\\plugins\\vue-dnd-zone (mode: 'client')
import nuxt_plugin_vueinfiniteloading_ba354f40 from 'nuxt_plugin_vueinfiniteloading_ba354f40' // Source: ..\\plugins\\vue-infinite-loading (mode: 'client')
import nuxt_plugin_catch_3542e5f6 from 'nuxt_plugin_catch_3542e5f6' // Source: ..\\plugins\\catch (mode: 'all')
import nuxt_plugin_vuetifymoney_56b8af0b from 'nuxt_plugin_vuetifymoney_56b8af0b' // Source: ..\\plugins\\vuetify-money (mode: 'client')
import nuxt_plugin_vuecookie_41e86efe from 'nuxt_plugin_vuecookie_41e86efe' // Source: ..\\plugins\\vue-cookie (mode: 'client')
import nuxt_plugin_smartlook_19c9b932 from 'nuxt_plugin_smartlook_19c9b932' // Source: ..\\plugins\\smartlook (mode: 'client')
import nuxt_plugin_vuevideobackground_24001f9d from 'nuxt_plugin_vuevideobackground_24001f9d' // Source: ..\\plugins\\vue-video-background (mode: 'client')
import nuxt_plugin_fineuploader_38d09d2a from 'nuxt_plugin_fineuploader_38d09d2a' // Source: ..\\plugins\\fine-uploader (mode: 'client')
import nuxt_plugin_vuesimplealert_359235de from 'nuxt_plugin_vuesimplealert_359235de' // Source: ..\\plugins\\vue-simple-alert (mode: 'client')
import nuxt_plugin_vue2googlemaps_94a82780 from 'nuxt_plugin_vue2googlemaps_94a82780' // Source: ..\\plugins\\vue2-google-maps (mode: 'client')
import nuxt_plugin_vuescrollloader_2cce7a56 from 'nuxt_plugin_vuescrollloader_2cce7a56' // Source: ..\\plugins\\vue-scroll-loader (mode: 'client')
import nuxt_plugin_webcamimport_da06bf1c from 'nuxt_plugin_webcamimport_da06bf1c' // Source: ..\\plugins\\web-cam-import (mode: 'client')
import nuxt_plugin_vuezoomonhover_24da5bcb from 'nuxt_plugin_vuezoomonhover_24da5bcb' // Source: ..\\plugins\\vue-zoom-on-hover (mode: 'client')
import nuxt_plugin_vueyoutube_e2e19eb4 from 'nuxt_plugin_vueyoutube_e2e19eb4' // Source: ..\\plugins\\vue-youtube (mode: 'client')
import nuxt_plugin_vue2geocoder_51b6d4ff from 'nuxt_plugin_vue2geocoder_51b6d4ff' // Source: ..\\plugins\\vue2-geocoder (mode: 'client')
import nuxt_plugin_auth_5ec683be from 'nuxt_plugin_auth_5ec683be' // Source: .\\auth.js (mode: 'all')
import nuxt_plugin_auth_6a7e4e1e from 'nuxt_plugin_auth_6a7e4e1e' // Source: ..\\plugins\\auth (mode: 'all')
import nuxt_plugin_html2canvas_557cc08e from 'nuxt_plugin_html2canvas_557cc08e' // Source: ..\\plugins\\html2canvas.js (mode: 'client')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    return this.$root.$options.$nuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const router = await createRouter(ssrContext, config)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"titleTemplate":"%s","title":"MultiSiteClient","htmlAttrs":{"lang":"en"},"link":[{"rel":"icon","type":"image\u002Fsvg","href":"\u002FCPFavicon.svg"},{"rel":"stylesheet","type":"text\u002Fcss","href":"https:\u002F\u002Fcdn.jsdelivr.net\u002Fnpm\u002F@mdi\u002Ffont@latest\u002Fcss\u002Fmaterialdesignicons.min.css"}],"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":""}],"style":[],"script":[]},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_plugin_2381e48f === 'function') {
    await nuxt_plugin_plugin_2381e48f(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginsdynamic05485147_0a0e0081 === 'function') {
    await nuxt_plugin_pluginsdynamic05485147_0a0e0081(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_4e07b9c5 === 'function') {
    await nuxt_plugin_plugin_4e07b9c5(app.context, inject)
  }

  if (typeof nuxt_plugin_recaptcha_6d721ca1 === 'function') {
    await nuxt_plugin_recaptcha_6d721ca1(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuewebcam_f588e88e === 'function') {
    await nuxt_plugin_vuewebcam_f588e88e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_nuxtvuexlocalstorage_2dc31336 === 'function') {
    await nuxt_plugin_nuxtvuexlocalstorage_2dc31336(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_toast_5aae783e === 'function') {
    await nuxt_plugin_toast_5aae783e(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxtvuexroutersync_2c511c1a === 'function') {
    await nuxt_plugin_nuxtvuexroutersync_2c511c1a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_pluginclient_d19f3476 === 'function') {
    await nuxt_plugin_pluginclient_d19f3476(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_pluginserver_062c5166 === 'function') {
    await nuxt_plugin_pluginserver_062c5166(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_06787732 === 'function') {
    await nuxt_plugin_axios_06787732(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_6359c072 === 'function') {
    await nuxt_plugin_pluginrouting_6359c072(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_681c3908 === 'function') {
    await nuxt_plugin_pluginmain_681c3908(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_3566aa80 === 'function') {
    await nuxt_plugin_axios_3566aa80(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_observevisibility_cb10434e === 'function') {
    await nuxt_plugin_observevisibility_cb10434e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vueuuid_9db4d5d0 === 'function') {
    await nuxt_plugin_vueuuid_9db4d5d0(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuedndzone_680f8b5c === 'function') {
    await nuxt_plugin_vuedndzone_680f8b5c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vueinfiniteloading_ba354f40 === 'function') {
    await nuxt_plugin_vueinfiniteloading_ba354f40(app.context, inject)
  }

  if (typeof nuxt_plugin_catch_3542e5f6 === 'function') {
    await nuxt_plugin_catch_3542e5f6(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuetifymoney_56b8af0b === 'function') {
    await nuxt_plugin_vuetifymoney_56b8af0b(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuecookie_41e86efe === 'function') {
    await nuxt_plugin_vuecookie_41e86efe(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_smartlook_19c9b932 === 'function') {
    await nuxt_plugin_smartlook_19c9b932(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuevideobackground_24001f9d === 'function') {
    await nuxt_plugin_vuevideobackground_24001f9d(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_fineuploader_38d09d2a === 'function') {
    await nuxt_plugin_fineuploader_38d09d2a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuesimplealert_359235de === 'function') {
    await nuxt_plugin_vuesimplealert_359235de(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vue2googlemaps_94a82780 === 'function') {
    await nuxt_plugin_vue2googlemaps_94a82780(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuescrollloader_2cce7a56 === 'function') {
    await nuxt_plugin_vuescrollloader_2cce7a56(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_webcamimport_da06bf1c === 'function') {
    await nuxt_plugin_webcamimport_da06bf1c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuezoomonhover_24da5bcb === 'function') {
    await nuxt_plugin_vuezoomonhover_24da5bcb(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vueyoutube_e2e19eb4 === 'function') {
    await nuxt_plugin_vueyoutube_e2e19eb4(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vue2geocoder_51b6d4ff === 'function') {
    await nuxt_plugin_vue2geocoder_51b6d4ff(app.context, inject)
  }

  if (typeof nuxt_plugin_auth_5ec683be === 'function') {
    await nuxt_plugin_auth_5ec683be(app.context, inject)
  }

  if (typeof nuxt_plugin_auth_6a7e4e1e === 'function') {
    await nuxt_plugin_auth_6a7e4e1e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_html2canvas_557cc08e === 'function') {
    await nuxt_plugin_html2canvas_557cc08e(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // If server-side, wait for async component to be resolved first
  if (process.server && ssrContext && ssrContext.url) {
    await new Promise((resolve, reject) => {
      router.push(ssrContext.url, resolve, (err) => {
        // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
        if (!err._isRouter) return reject(err)
        if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

        // navigated to a different route in router guard
        const unregister = router.afterEach(async (to, from) => {
          ssrContext.url = to.fullPath
          app.context.route = await getRouteData(to)
          app.context.params = to.params || {}
          app.context.query = to.query || {}
          unregister()
          resolve()
        })
      })
    })
  }

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
